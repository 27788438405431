<template>
    <div class="topbar" :class="[$mq]">
        <div id="categories">
            <div class="option" v-for="category in sortedCategories" :class="[category.color, { selected: category.selected }]" @click="selectCategory(category.id)">
                <span class="text">{{ category.name }}</span>
                <span v-if="category.qty > 0" class="quantity">{{ category.qty }}</span>
            </div>
        </div>
        <div id="actions">
            <div class="add-timer" @click="$emit('addTimer')"></div>
            <div class="more-actions-container">
                <div class="more-button" :class="{ active: showOptions }" @click="$emit('showOptions')"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: { type: Object }
    },
    data() {
        return {
            showOptions: false
        }
    },
    computed: {
        sortedCategories() {
            let sorted = [
                ...Object.values(this.categories)
                    .filter((item) => item.id !== false)
                    .sort((a, b) => a.order - b.order)
            ]
            // console.log(JSON.parse(JSON.stringify(sorted)))
            if (sorted.length > 0) {
                sorted.splice(0, 0, this.categories[false])
                const selected = sorted.find((item) => item.selected)
                if (!selected) {
                    sorted[0].selected = true
                }
            }

            return sorted
        }
    },
    methods: {
        selectCategory(category) {
            this.$emit('selectCategory', category)
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.topbar {
    height: 54px;
    padding: 10px;
    display: flex;

    #categories {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @include border-radius(4px);
        width: calc(100% - 100px);
        max-width: 100%;
        height: 45px !important;
        margin: 0 auto 0 0;
        padding: 4px;
        gap: 4px;
        background-color: #fff;
        overflow-y: hidden;
        overflow-x: auto;
        scrollbar-width: thin; /* Para Firefox */
        &::-webkit-scrollbar {
            height: 5px !important; /* Altura del scrollbar */
            background-color: #ccc; /* Color del fondo del scrollbar */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #888; /* Color del "pulgar" del scrollbar */
            border-radius: 10px;
        }

        .option {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(4px);
            width: 48%;
            text-align: center;
            height: 34px;
            cursor: pointer;
            padding: 5px;
            text-transform: uppercase;

            &.selected {
                font-family: $text-sbold;
                background-color: $main-t80;
                color: $main-dark;
                cursor: default;

                .text {
                    font-family: Avenir-Bold;
                    color: $main-dark;
                }
            }
            &:hover:not(.selected) {
                @include bgHover(#fff);
            }

            .text {
                @include font-size(sm);
                @include text-ellipsis();
                font-family: $text;
                color: $neutro-s50;
                text-align: center;
                padding: 0 10px;

                &.selected {
                    font-family: $text-sbold;
                    background-color: $main-t80;
                    color: $main-dark;
                    cursor: default;
                }
            }

            .quantity {
                background-color: $inactive;
                padding: 3px 6px;
                font-size: 14px;
                border-radius: 4px;
                font-family: Avenir-Bold;
                background-color: $color-error-500;
                color: #fff;
            }
        }
    }

    #actions {
        display: flex;

        .add-timer {
            @include display-flex();
            @include align-items(center);
            @include background($image: img('add_ffffff.svg'), $size: 20px, $position: center);
            @include border-radius(4px);
            @include font-size(ml);
            height: 43px;
            width: 43px;
            min-width: 43px;
            min-height: 43px;
            background-color: #1a9980;
            font-family: $text;
            cursor: pointer;
        }

        .more-actions-container {
            height: auto;
            /* position: absolute;
            z-index: $z-menu-top;
            right: 8px; */
            margin-left: 4px;
            .more-button {
                @extend .interaction;
                @include background($image: img('eye_preview_neutro.svg'), $size: 30px);
                @include border-radius(4px);
                @include align-self(center);
                background-color: $default-sec-color;
                width: 43px;
                height: 43px;
                &.active {
                    background-image: img('more_horizontal_aaaaaa.svg') !important;
                }
            }
        }
    }
}
</style>
